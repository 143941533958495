<template>
  <div style="padding:15px;background:#fff;" v-if="Object.keys(salesperson).length > 0">
    <p>
      <small>
        客户经理：{{ salesperson.name }}
      </small>
    </p>
  </div>
  <van-cell-group title="选择充值金额"></van-cell-group>
  <van-grid :column-num="2">
    <template v-for="item in deposits" :key="item">
      <van-grid-item @click="onSelectAmount(item)">
        <div class="ok">
          <p>
            &yen;{{ item.depositAmount }}
            <template v-if="selected === item.productId">
              [已选择]
            </template>
          </p>
          <p style="color:#999;margin:5px 0 0 0;" v-if="item.depositGiftAmount > 0">
            <small>
              送&yen;{{ item.depositGiftAmount }}
            </small>
          </p>
        </div>
      </van-grid-item>
    </template>
  </van-grid>
  <p style="margin:15px;">
    <small>充值金额无法退款</small>
  </p>
  <van-radio-group v-model="checked">
    <van-cell-group>
      <van-cell title="微信支付" clickable @click="checked = 'WECHAT'">
        <template #right-icon>
          <van-radio name="WECHAT" />
        </template>
      </van-cell>
      <!-- <van-cell title="内部支付" clickable @click="checked = 'SYSTEM'">
        <template #right-icon>
          <van-radio name="SYSTEM" />
        </template>
      </van-cell> -->
    </van-cell-group>
  </van-radio-group>
  <div style="margin: 16px;">
    <van-button block type="primary" @click="onConfirm">
      确认支付
    </van-button>
  </div>
  <div style="margin: 16px;">
    <van-button block :to="{ name: 'DepositLog' }">
      历史记录
    </van-button>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import { callWeixinPay } from '@/util/util'

export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      customer: {},
      deposits: [],
      buyerId: Cookies.get('buyerId'),
      storeId: Cookies.get('storeId'),
      wechatOpenid: Cookies.get('wechatOpenid'),
      memberId: Cookies.get('memberId'),
      salespersonId: Cookies.get('salespersonId'),
      salesperson: {},
      tradeNumber: '',
      checked: 'WECHAT',
      selected: 0
    })
    const applyPay = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        let payHttpUrl = '/order.applyPay'
        if (res.data.storeMerchant) {
          if (res.data.storeMerchant.applymentState === 'FINISH') {
            payHttpUrl = '/applyMerchantPay'
          }
        }
        post(payHttpUrl, {
          storeId: state.storeId,
          wechatOpenid: state.wechatOpenid,
          tradeNumber: state.tradeNumber,
          paymentMethod: state.checked
        }).then(res => {
          if (res.code === 0) {
            if (state.checked === 'WECHAT') {
              let payParams = {}
              if (payHttpUrl === '/order.applyPay') {
                payParams = res.data.wechatPayParams
              } else {
                payParams = res.data
                payParams.package = res.data.packageValue
              }
              console.log(payParams)
              callWeixinPay(payParams).then(res => {
                if (res.result) {
                  Toast.success('支付成功')
                  useRouter.push({
                    name: 'User'
                  })
                } else {
                  Toast.fail('支付取消')
                }
              })
            } else if (state.checked === 'BALANCE') {
              Toast.success('支付成功')
              useRouter.push({
                name: 'User'
              })
            }
          } else {
            Toast(res.msg)
          }
        })
      })
    }
    const onConfirm = () => {
      if (state.selected === 0) {
        Toast('请选择充值金额')
        return
      }
      Toast.loading({ duration: 0, forbidClick: true })
      post('/order.memberRecharge', {
        memberId: state.memberId,
        productId: state.selected,
        salespersonId: state.salespersonId
      }).then(res => {
        if (res.code === 0) {
          state.tradeNumber = res.data.tradeNumber
          applyPay()
        } else {
          Toast.clear()
          Toast(res.msg)
        }
      })
    }
    const onSelect = (item) => {
      // state.payment.show = false
      // Toast.loading({ duration: 0, forbidClick: true })
      // post('/order.memberRecharge', {
      //   ...state.params
      // }).then(res => {
      //   Toast.clear()
      //   if (res.code === 0) {
      //     callWeixinPay(res.data.weixinPayParams).then(res => {
      //       if (res.result) {
      //         Toast.success('支付成功')
      //       } else {
      //         Toast.fail('支付取消')
      //       }
      //     })
      //   } else {
      //     Toast(res.msg)
      //   }
      // })
    }
    const onSelectAmount = (item) => {
      state.selected = item.productId
    }
    const onA = () => {
    }
    const init = () => {
      post('/product.list', {
        storeId: state.storeId,
        bizScope: 'DEPOSIT'
      }).then(res => {
        state.deposits = res.data.content
      })
      if (state.buyerId) {
        post('/customer.get', {
          buyerId: state.buyerId,
          storeId: state.storeId
        }).then(res => {
          if (res.code === 0) {
            // state.salesperson = {
            //   name: res.data.staffName
            // }
            console.log(res.data.staffName)
            state.salesperson.name = res.data.staffName
          }
        })
      }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onConfirm,
      onSelectAmount,
      onSelect,
      onA
    }
  }
}
</script>

<style>
</style>
